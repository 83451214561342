import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RouteModal.css';

const RouteModal = ({ route, onClose }) => {
  const [showCompletionForm, setShowCompletionForm] = useState(false);
  const [completionTag, setCompletionTag] = useState('');
  const [gradeVote, setGradeVote] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isFlipped, setIsFlipped] = useState(false); // For flipping the card
  const [imageRect, setImageRect] = useState(null);

  useEffect(() => {
    const img = document.getElementById('route-image');
    if (img) {
      setImageRect(img.getBoundingClientRect());
    }
  }, [route]);

  const handleFormToggle = () => {
    setShowCompletionForm(!showCompletionForm);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const calculateGradeVoteTally = () => {
    return route.completions.reduce((tally, completion) => {
      if (completion.votedGrade === 'Too High') return tally - 1;
      if (completion.votedGrade === 'Too Low') return tally + 1;
      return tally;
    }, 0);
  };

  const getUniqueCompletions = (completions) => {
    const seenTags = new Set();
    return completions.filter((completion) => {
      if (seenTags.has(completion.tag)) {
        return false;
      }
      seenTags.add(completion.tag);
      return true;
    });
  };

  const handleSubmit = async () => {
    const completionData = {
      tag: completionTag.toUpperCase(),
      votedGrade: gradeVote,
      date: new Date().toISOString(),
    };

    try {
      await axios.post('https://bouldering-backend.vercel.app/api/routes', {
        routeId: route._id,
        completion: completionData,
      });
      setSuccessMessage('Completion added successfully!');
      setErrorMessage('');
      setShowCompletionForm(false);
      setCompletionTag('');
      setGradeVote('');
    } catch (error) {
      console.error('Error adding completion:', error);
      setErrorMessage('Failed to add completion. Please try again.');
      setSuccessMessage('');
    }
  };

  const gradeVoteTally = calculateGradeVoteTally();

  return (
    <div className="route-modal">
      <div className={`route-modal-content ${isFlipped ? 'flipped' : ''}`}>
        {/* Front View */}
        {!isFlipped && (
          <div className="card-front">
            <span className="close" onClick={onClose}>
              &times;
            </span>
            <h2>{route.name}</h2>
            <p>Difficulty: {route.difficulty}</p>
            <p>Comment: {route.comment}</p>
            <p>Total Completions: {route.completions.length}</p>
            <p>
              Grade Vote: {route.difficulty}{' '}
              <span className="grade-vote">
                {gradeVoteTally > 0 ? '↑' : gradeVoteTally < 0 ? '↓' : '→'} {gradeVoteTally}
              </span>
            </p>
            <div className="image-wrapper">
              <img
                src={`${process.env.PUBLIC_URL}/${encodeURIComponent(route.image)}`}
                alt={route.name}
                className="selected-image"
                id="route-image"
              />
              {imageRect &&
                route.holds.map((hold, index) => {
                  const x = (hold.x / 100) * imageRect.width;
                  const y = (hold.y / 100) * imageRect.height;

                  return (
                    <div
                      key={index}
                      className={`hold ${hold.shape} ${hold.fill} ${hold.size} ${hold.type}`}
                      style={{
                        position: 'absolute',
                        top: `${y}px`,
                        left: `${x}px`,
                        backgroundColor: hold.fill === 'filled' ? hold.color : 'transparent',
                        borderColor: hold.color,
                      }}
                    >
                      {hold.type === 'start' && <span className="hold-label">S</span>}
                      {hold.type === 'top' && <span className="hold-label">T</span>}
                    </div>
                  );
                })}
            </div>
            <div className="button-group">
              <button className="completion-button" onClick={handleFormToggle}>
                Mark as Completed
              </button>
              <button
                className="leaderboard-button"
                onClick={handleFlip}
                aria-label="View completions"
                tabIndex={0} // Ensures keyboard focus
              >
                View Leaderboard
              </button>
            </div>
            {showCompletionForm && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <label>
                  Completion Tag (3 letters):
                  <input
                    type="text"
                    value={completionTag}
                    maxLength={3}
                    onChange={(e) => setCompletionTag(e.target.value.toUpperCase())}
                  />
                </label>
                <label>
                  Grade Vote:
                  <select value={gradeVote} onChange={(e) => setGradeVote(e.target.value)}>
                    <option value="">Select</option>
                    <option value="Correct">Correct</option>
                    <option value="Too High">Too High</option>
                    <option value="Too Low">Too Low</option>
                  </select>
                </label>
                <button type="submit">Submit</button>
              </form>
            )}
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        )}

        {/* Back View */}
        {isFlipped && (
          <div className="card-back">
            <h3>First Completions</h3>
            <ol>
              {getUniqueCompletions(route.completions)
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((completion, index) => (
                  <li key={index}>
                    {completion.tag} - {new Date(completion.date).toLocaleDateString()}
                  </li>
                ))}
            </ol>
            <button onClick={handleFlip} aria-label="Back to Route">
              Back to Route
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RouteModal;
