import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to Reittireiska!</h1>

      {/* Work in progress note placed here */}
      <div className="work-in-progress">
        <p><strong>Note:</strong> Made some changes to make it easier to navigate routes. Routes can be now completed multiple times by same tag. Only first completion shows in leaderboard, this change made for a bigger future "data is beatiful" update in mind. </p>
      </div>

      <p>Create and share your bouldering and rope routes with ease.</p>
      <div className="features">
        <div className="feature">
          <Link to="/create-route" className="feature-link">
            <h2>Create Routes</h2>
            <p>Design your own bouldering and rope routes by selecting holds and then tapping the image where you want to place the hold.</p>
          </Link>
        </div>
        <div className="feature">
          <Link to="/view-routes" className="feature-link">
            <h2>View Routes</h2>
            <p>Browse and view routes created by others. Filter by wall and difficulty to find the perfect challenge.</p>
          </Link>
        </div>
      </div>
      
      {/* Add your name at the bottom right */}
    </div>
  );
};

export default Home;
