import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RouteCard from './RouteCard';
import RouteModal from './RouteModal';
import './ViewRoutes.css';

const ViewRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [filterBy, setFilterBy] = useState('popularity'); // Default filter set to popularity
  const [difficultyRange, setDifficultyRange] = useState({ low: '', high: '' }); // State for difficulty range filter
  const [routeName, setRouteName] = useState(''); // State for route name search

  useEffect(() => {
    axios
      .get('https://bouldering-backend.vercel.app/api/routes')
      .then((response) => {
        const data = response.data.map((route) => ({
          ...route,
          createdAt: new Date(route.createdAt), // Parse dates for sorting
        }));
        setRoutes(data);

        // Apply default filter (popularity) after fetching data
        const sortedByPopularity = data.sort((a, b) => b.completions.length - a.completions.length);
        setFilteredRoutes(sortedByPopularity);
      })
      .catch((error) => {
        console.error('Error fetching routes', error);
      });
  }, []);

  useEffect(() => {
    // Reapply filter when filterBy, difficultyRange, routeName, or routes change
    applyFilter();
  }, [filterBy, difficultyRange, routeName, routes]);

  const applyFilter = () => {
    let filtered = [...routes];

    // Apply name filter if a name is entered
    if (routeName.trim()) {
      filtered = filtered.filter((route) =>
        route.name.toLowerCase().includes(routeName.toLowerCase())
      );
    }

    // Apply difficulty range filter if selected
    if (difficultyRange.low && difficultyRange.high) {
      filtered = filtered.filter((route) => {
        const routeDifficulty = parseDifficulty(route.difficulty);
        return (
          routeDifficulty >= parseDifficulty(difficultyRange.low) &&
          routeDifficulty <= parseDifficulty(difficultyRange.high)
        );
      });
    }

    // Apply sorting based on the filterBy selection
    if (filterBy === 'popularity') {
      filtered.sort((a, b) => b.completions.length - a.completions.length);
    } else if (filterBy === 'grade-low-high') {
      filtered.sort((a, b) => parseDifficulty(a.difficulty) - parseDifficulty(b.difficulty));
    } else if (filterBy === 'grade-high-low') {
      filtered.sort((a, b) => parseDifficulty(b.difficulty) - parseDifficulty(a.difficulty));
    } else if (filterBy === 'newest') {
      filtered.sort((a, b) => b.createdAt - a.createdAt);
    }

    setFilteredRoutes(filtered);
  };

  const parseDifficulty = (difficulty) => {
    const gradeScale = {
      '4': 1, '4+': 2, '5': 3, '5+': 4,
      '6a': 5, '6a+': 6, '6b': 7, '6b+': 8,
      '6c': 9, '6c+': 10, '7a': 11, '7a+': 12,
      '7b': 13, '7b+': 14, '7c': 15, '7c+': 16,
      '8a': 17, '8a+': 18, '8b': 19, '8b+': 20,
      '8c': 21, '8c+': 22, '9a': 23,
    };
    return gradeScale[difficulty] || 0;
  };

  const handleCardClick = (route) => {
    setSelectedRoute(route);
  };

  const handleCloseModal = () => {
    setSelectedRoute(null);
  };

  return (
    <div className="view-routes-container">
      <h1>View Routes</h1>
      <div className="filters">
        {/* Dropdown to select filter */}
        <div>
          <label>Filter by:</label>
          <select value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
            <option value="popularity">Popularity</option>
            <option value="grade-low-high">Grade (Low to High)</option>
            <option value="grade-high-low">Grade (High to Low)</option>
            <option value="newest">Newest</option>
          </select>
        </div>

        {/* Difficulty Range Filter */}
        <div className="difficulty-range-filter">
          <label>Grade Range:</label>
          <input
            type="text"
            placeholder="Min (e.g., 6a)"
            value={difficultyRange.low}
            onChange={(e) => setDifficultyRange({ ...difficultyRange, low: e.target.value })}
          />
          <input
            type="text"
            placeholder="Max (e.g., 7b)"
            value={difficultyRange.high}
            onChange={(e) => setDifficultyRange({ ...difficultyRange, high: e.target.value })}
          />
        </div>

        {/* Route Name Search */}
        <div className="route-name-search">
          <label>Search by Name:</label>
          <input
            type="text"
            placeholder="Enter route name"
            value={routeName}
            onChange={(e) => setRouteName(e.target.value)}
          />
        </div>
      </div>

      <div className="route-cards-container">
        {filteredRoutes.map((route, index) => (
          <RouteCard key={index} route={route} onClick={() => handleCardClick(route)} />
        ))}
      </div>

      {selectedRoute && (
        <RouteModal route={selectedRoute} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default ViewRoutes;
